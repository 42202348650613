.encore-ad-analytics-light-theme, .encore-ad-analytics-light-theme .encore-base-set {
  --background-base: #fff;
  --background-highlight: #f6f6f6;
  --background-press: #b7b7b7;
  --background-elevated-base: #fff;
  --background-elevated-highlight: #f6f6f6;
  --background-elevated-press: #b7b7b7;
  --background-tinted-base: #00000014;
  --background-tinted-highlight: #0000000d;
  --background-tinted-press: #0000001f;
  --background-unsafe-for-small-text-base: #fff;
  --background-unsafe-for-small-text-highlight: #fff;
  --background-unsafe-for-small-text-press: #fff;
  --text-base: #000;
  --text-subdued: #6a6a6a;
  --text-bright-accent: #567408;
  --text-negative: #d31225;
  --text-warning: #9d5a00;
  --text-positive: #117a37;
  --text-announcement: #0c67d3;
  --essential-base: #000;
  --essential-subdued: #878787;
  --essential-bright-accent: #6d930a;
  --essential-negative: #e91429;
  --essential-warning: #c77200;
  --essential-positive: #169b45;
  --essential-announcement: #0d72ea;
  --decorative-base: #000;
  --decorative-subdued: #dedede;
}

.encore-ad-analytics-light-theme > *, .encore-ad-analytics-light-theme .encore-base-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-bright-accent-set {
  --background-base: #cff56a;
  --background-highlight: #e2f9a6;
  --background-press: #8bba0c;
  --background-elevated-base: #e2f9a6;
  --background-elevated-highlight: #e2f9a6;
  --background-elevated-press: #8bba0c;
  --background-tinted-base: #cff56a;
  --background-tinted-highlight: #cff56a;
  --background-tinted-press: #cff56a;
  --background-unsafe-for-small-text-base: #cff56a;
  --background-unsafe-for-small-text-highlight: #cff56a;
  --background-unsafe-for-small-text-press: #cff56a;
  --text-base: #000;
  --text-subdued: #000;
  --text-bright-accent: #000;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #000;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #000;
  --essential-bright-accent: #000;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #000;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #a8e20f;
}

.encore-ad-analytics-light-theme .encore-bright-accent-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-negative-set {
  --background-base: #e91429;
  --background-highlight: #de1327;
  --background-press: #920d1a;
  --background-elevated-base: #de1327;
  --background-elevated-highlight: #de1327;
  --background-elevated-press: #920d1a;
  --background-tinted-base: #e91429;
  --background-tinted-highlight: #e91429;
  --background-tinted-press: #e91429;
  --background-unsafe-for-small-text-base: #e91429;
  --background-unsafe-for-small-text-highlight: #e91429;
  --background-unsafe-for-small-text-press: #e91429;
  --text-base: #fff;
  --text-subdued: #fff;
  --text-bright-accent: #fff;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #fff;
  --essential-bright-accent: #fff;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #ee3a4c;
}

.encore-ad-analytics-light-theme .encore-negative-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-warning-set {
  --background-base: #ffa42b;
  --background-highlight: #ffaf45;
  --background-press: #c57100;
  --background-elevated-base: #ffaf45;
  --background-elevated-highlight: #ffaf45;
  --background-elevated-press: #c57100;
  --background-tinted-base: #ffa42b;
  --background-tinted-highlight: #ffa42b;
  --background-tinted-press: #ffa42b;
  --background-unsafe-for-small-text-base: #ffa42b;
  --background-unsafe-for-small-text-highlight: #ffa42b;
  --background-unsafe-for-small-text-press: #ffa42b;
  --text-base: #000;
  --text-subdued: #000;
  --text-bright-accent: #000;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #000;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #000;
  --essential-bright-accent: #000;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #000;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #f18900;
}

.encore-ad-analytics-light-theme .encore-warning-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-positive-set {
  --background-base: #1ed760;
  --background-highlight: #1fdf64;
  --background-press: #169c46;
  --background-elevated-base: #1fdf64;
  --background-elevated-highlight: #1fdf64;
  --background-elevated-press: #169c46;
  --background-tinted-base: #1ed760;
  --background-tinted-highlight: #1ed760;
  --background-tinted-press: #1ed760;
  --background-unsafe-for-small-text-base: #1ed760;
  --background-unsafe-for-small-text-highlight: #1ed760;
  --background-unsafe-for-small-text-press: #1ed760;
  --text-base: #000;
  --text-subdued: #000;
  --text-bright-accent: #000;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #000;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #000;
  --essential-bright-accent: #000;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #000;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #1abc54;
}

.encore-ad-analytics-light-theme .encore-positive-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-announcement-set {
  --background-base: #0d72ea;
  --background-highlight: #0c6ddf;
  --background-press: #084791;
  --background-elevated-base: #0c6ddf;
  --background-elevated-highlight: #0c6ddf;
  --background-elevated-press: #084791;
  --background-tinted-base: #0d72ea;
  --background-tinted-highlight: #0d72ea;
  --background-tinted-press: #0d72ea;
  --background-unsafe-for-small-text-base: #0d72ea;
  --background-unsafe-for-small-text-highlight: #0d72ea;
  --background-unsafe-for-small-text-press: #0d72ea;
  --text-base: #fff;
  --text-subdued: #fff;
  --text-bright-accent: #fff;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #fff;
  --essential-bright-accent: #fff;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #2a86f3;
}

.encore-ad-analytics-light-theme .encore-announcement-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-inverted-dark-set {
  --background-base: #000;
  --background-highlight: #0d0d0d;
  --background-press: #3f3f3f;
  --background-elevated-base: #0d0d0d;
  --background-elevated-highlight: #0d0d0d;
  --background-elevated-press: #3f3f3f;
  --background-tinted-base: #000;
  --background-tinted-highlight: #000;
  --background-tinted-press: #000;
  --background-unsafe-for-small-text-base: #000;
  --background-unsafe-for-small-text-highlight: #000;
  --background-unsafe-for-small-text-press: #000;
  --text-base: #fff;
  --text-subdued: #8f8f8f;
  --text-bright-accent: #cff56a;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #cff56a;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #5e5e5e;
  --essential-bright-accent: #cff56a;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #cff56a;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #1f1f1f;
}

.encore-ad-analytics-light-theme .encore-inverted-dark-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-inverted-light-set {
  --background-base: #fff;
  --background-highlight: #f6f6f6;
  --background-press: #b7b7b7;
  --background-elevated-base: #f6f6f6;
  --background-elevated-highlight: #f6f6f6;
  --background-elevated-press: #b7b7b7;
  --background-tinted-base: #fff;
  --background-tinted-highlight: #fff;
  --background-tinted-press: #fff;
  --background-unsafe-for-small-text-base: #fff;
  --background-unsafe-for-small-text-highlight: #fff;
  --background-unsafe-for-small-text-press: #fff;
  --text-base: #000;
  --text-subdued: #5e5e5e;
  --text-bright-accent: #5c7c08;
  --text-negative: #000;
  --text-warning: #000;
  --text-positive: #5c7c08;
  --text-announcement: #000;
  --essential-base: #000;
  --essential-subdued: #8f8f8f;
  --essential-bright-accent: #749c0a;
  --essential-negative: #000;
  --essential-warning: #000;
  --essential-positive: #749c0a;
  --essential-announcement: #000;
  --decorative-base: #000;
  --decorative-subdued: #dedede;
}

.encore-ad-analytics-light-theme .encore-inverted-light-set > * {
  --parents-essential-base: #000;
}

.encore-ad-analytics-light-theme .encore-muted-accent-set {
  --background-base: #121212;
  --background-highlight: #1a1a1a;
  --background-press: #000;
  --background-tinted-base: #ffffff12;
  --background-tinted-highlight: #ffffff1a;
  --background-tinted-press: #ffffff0a;
  --background-elevated-base: #1a1a1a;
  --background-elevated-highlight: #1a1a1a;
  --background-elevated-press: #000;
  --background-unsafe-for-small-text-base: #121212;
  --background-unsafe-for-small-text-highlight: #121212;
  --background-unsafe-for-small-text-press: #121212;
  --text-base: #fff;
  --text-subdued: #a7a7a7;
  --text-bright-accent: #cff56a;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #cff56a;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #727272;
  --essential-bright-accent: #cff56a;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #cff56a;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #292929;
}

.encore-ad-analytics-light-theme .encore-muted-accent-set > * {
  --parents-essential-base: #fff;
}

.encore-ad-analytics-light-theme .encore-over-media-set {
  --background-base: #0000008a;
  --background-highlight: #0000008f;
  --background-press: #000000b8;
  --background-unsafe-for-small-text-base: #0000006b;
  --background-unsafe-for-small-text-highlight: #00000070;
  --background-unsafe-for-small-text-press: #0000009c;
  --background-elevated-base: #0000008f;
  --background-elevated-highlight: #0000008f;
  --background-elevated-press: #000000b8;
  --background-tinted-base: #0000008a;
  --background-tinted-highlight: #0000008a;
  --background-tinted-press: #0000008a;
  --text-base: #fff;
  --text-subdued: #fff;
  --text-bright-accent: #fff;
  --text-negative: #fff;
  --text-warning: #fff;
  --text-positive: #fff;
  --text-announcement: #fff;
  --essential-base: #fff;
  --essential-subdued: #fff;
  --essential-bright-accent: #fff;
  --essential-negative: #fff;
  --essential-warning: #fff;
  --essential-positive: #fff;
  --essential-announcement: #fff;
  --decorative-base: #fff;
  --decorative-subdued: #ffffff21;
}

.encore-ad-analytics-light-theme .encore-over-media-set > * {
  --parents-essential-base: #fff;
}

html, button, input, select, textarea {
  font-family: inherit;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

audio, canvas, progress, video {
  display: inline-block;
}

progress {
  vertical-align: baseline;
}

template {
  display: none;
}

svg:not(:root) {
  overflow: hidden;
}

p {
  margin: 0 0 20px;
  line-height: 1.5;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 700;
}

h1 {
  font-size: 2rem;
  line-height: 2.375rem;
}

h2 {
  font-size: 1.625rem;
  line-height: 2rem;
}

h3 {
  font-size: 1.375rem;
  line-height: 1.75rem;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

h5 {
  font-size: 1rem;
  line-height: 1.375rem;
}

h6 {
  font-size: .875rem;
  line-height: 1.25rem;
}

b, strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

ul, ol {
  margin: 20px 0;
  padding: 0 0 0 40px;
}

dl:before, dl:after {
  content: " ";
  display: table;
}

dl:after {
  clear: both;
}

dl dt {
  float: left;
  width: 25%;
  font-weight: 400;
  display: block;
}

dl dd {
  display: block;
  overflow: hidden;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

label, button, optgroup, select, textarea {
  font: inherit;
  color: inherit;
  border-radius: 0;
  margin: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button[disabled], html input[disabled] {
  cursor: not-allowed;
}

input::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input:-ms-input-placeholder {
  color: #999;
}

.transcript-word {
  background: #fff;
}

.transcript-word:hover, .transcript-word.active {
  background: #ffa500b3;
}

.transcript-word.highlight {
  background: #ffff00b3;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("CircularSp-Book.468342bb.woff2") format("woff2");
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("CircularSp-Book.468342bb.woff2") format("woff2");
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("CircularSp-Medium.f2edf72a.woff2") format("woff2");
}

@font-face {
  font-family: CircularSp;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("CircularSp-Bold.51799f6d.woff2") format("woff2");
}

@font-face {
  font-family: CircularSpTitle;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("CircularSpTitle-Bold.3a5f908b.woff2") format("woff2");
}

@font-face {
  font-family: CircularSpTitle;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("CircularSpTitle-Black.21f59116.woff2") format("woff2");
}

html {
  --white: #fff;
  --black: #000;
  --gray-1: #f6f6f6;
  --gray-2: #f2f3f5;
  --gray-3: #e1e4e8;
  --gray-4: #dadde1;
  --gray-5: #ccd0d5;
  --gray-6: #bec3c9;
  --gray-7: #8d949e;
  --gray-8: #6a6a6a;
  --gray-9: #444950;
  --gray-10: #1c1e21;
  --spotify-yellow: #cdf564;
  --green-podsights: #cdf564;
  --blue-dark: #460e3c;
  --blue: #a1258a;
  --blue-80: #b551a1;
  --blue-60: #c77cb9;
  --blue-40: #d9a8d0;
  --blue-20: #ecd3e8;
  --blue-10: #f6e9f3;
  --green-dark: #00975d;
  --green: #1ed760;
  --green-80: #45e57e;
  --green-60: #73ec9e;
  --green-40: #a2f2bf;
  --green-20: #d0f9df;
  --green-10: #e8fcef;
  --highlighter-dark: #847504;
  --highlighter: #e7fe02;
  --highlighter-80: #e7fe02;
  --highlighter-60: #f1fe67;
  --highlighter-40: #f5ff9a;
  --highlighter-20: #faffcc;
  --highlighter-10: #fdffe6;
  --red-orange-dark: #e12a16;
  --red-orange: #ff4632;
  --red-orange-80: #ff6b5b;
  --red-orange-60: #ff9084;
  --red-orange-40: #ffb5ad;
  --red-orange-20: #ffdad6;
  --red-orange-10: #ffecea;
  --pink: #ff94d5;
  --pink-80: #ffa9dd;
  --pink-60: #ffbfe6;
  --pink-40: #ffd4ee;
  --pink-20: #ffeaf7;
  --pink-10: #fff4fb;
  --default: #bdbdbd;
  --primary: var(--blue);
  --secondary: var(--spotify-yellow);
  --error: var(--red-orange);
  --info: var(--blue);
  --success: #16d0a6;
  --warning: #ffa42b;
  --text-on-primary: var(--white);
  --text-on-secondary: var(--gray-10);
  --text-on-error: var(--white);
  --text-on-info: var(--white);
  --text-on-success: var(--white);
  --text-on-warning: var(--white);
  --text-primary: var(--blue-dark);
  --text-secondary: var(--green-dark);
  --text-error: var(--red-orange-dark);
  --text-success: var(--green-dark);
  --text-info: var(--blue-dark);
  --text-warning: var(--highlighter-dark);
  --font-family-base: CircularSp, Helvetica, Arial, sans-serif;
  --font-family-title: CircularSpTitle, Helvetica, Arial, sans-serif;
  --font-size-h1: 2.5rem;
  --font-size-h2: 1.75rem;
  --font-size-h3: 1.375rem;
  --font-size-h4: 1.125rem;
  --font-size-h5: 1.0625rem;
  --font-size-body-1: 1rem;
  --font-size-body-2: .875rem;
  --font-size-body-3: .8125rem;
  --font-size-body-4: .6875rem;
  --line-height-xs: 1.3;
  --line-height-sm: 1.4;
  --line-height-md: 1.5;
  --line-height-lg: 1.6;
  --line-height-xl: 1.75;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-extrabold: 900;
  --spacing-1: .25rem;
  --spacing-2: .5rem;
  --spacing-3: .75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-13: 3.25rem;
  --spacing-14: 3.5rem;
  --spacing-15: 3.75rem;
  --radius-1: .75rem;
  --radius-2: 1.25rem;
  --radius-3: 2rem;
  --radius-4: 3rem;
  --stack-padding-condensed: var(--size-2);
  --stack-padding-normal: var(--size-4);
  --stack-padding-spacious: var(--size-6);
  --stack-gap-condensed: var(--size-2);
  --stack-gap-normal: var(--size-4);
  --stack-gap-spacious: var(--size-6);
  --app-left-nav-width: 13rem;
  --input-height-sm: 2.125rem;
  --input-height-md: 2.625rem;
  --input-border: 1px solid var(--border-muted);
  --input-border-error: 1px solid var(--border-muted);
  --input-border-radius-sm: 3px;
  --input-border-radius-md: 4px;
  --advisor-affinity-color: #953086;
  --advisor-affinity-bg: transparent;
  --advisor-affinity-megaphone-color: #6347ff;
  --advisor-affinity-megaphone-bg: #dfd7fe;
  --advisor-reach-color: #953086;
  --advisor-reach-bg: transparent;
}

html {
  --bg-default: var(--white);
  --bg-subtle: var(--gray-1);
  --bg-muted: var(--gray-3);
  --bg-surface: var(--white);
  --bg-overlay: var(--white);
  --bg-tooltip: #24292e;
  --bg-default-hover: var(--gray-2);
  --bg-subtle-hover: #f8f8f9;
  --bg-surface-hover: var(--gray-1);
  --bg-nav-hover: #c3cdff40;
  --border-default: var(--gray-3);
  --border-subtle: var(--gray-4);
  --border-muted: var(--gray-5);
  --border-subtle-hover: var(--gray-6);
  --border-muted-hover: var(--gray-7);
  --icon-default: var(--gray-9);
  --icon-subtle: var(--gray-8);
  --icon-muted: var(--gray-7);
  --shadow-sm: 0 1px 0 #1b1f230a;
  --shadow-md: 0 1px 4px 2px #959da554;
  --shadow-lg: 0 8px 24px #959da533;
  --shadow-xl: 0 12px 48px #959da54d;
  --shadow-focus: 0 0 0 3px #0366d64d;
  --table-border-row: var(--gray-3);
  --counter-bg-1: #6a737d;
  --counter-text-1: #fff;
  --inverse: #24292e;
  --text-on-inverse: var(--white);
  --text-default: var(--gray-10);
  --text-subtle: var(--gray-9);
  --text-muted: var(--gray-8);
  --text-placeholder: var(--gray-7);
  --text-disabled: var(--gray-5);
  --text-tooltip: var(--white);
  --table-header-bg: var(--gray-1);
}

html[data-theme="dark"] {
  --bg-default: #18191a;
  --bg-subtle: #242526;
  --bg-muted: #242526;
  --bg-surface: #242526;
  --bg-overlay: #252526;
  --bg-tooltip: #21262d;
  --bg-default-hover: #ffffff1a;
  --bg-subtle-hover: #161b22;
  --bg-surface-hover: #30363d;
  --bg-nav-hover: #c3cdff40;
  --border-default: var(--gray-9);
  --border-subtle: var(--gray-7);
  --border-muted: var(--gray-8);
  --border-muted-hover: var(--gray-7);
  --icon-default: #e4e6eb;
  --icon-subtle: #b0b3b8;
  --icon-muted: #484f58;
  --shadow-sm: 0 0 transparent;
  --shadow-md: 0 1px 4px #010409;
  --shadow-lg: 0 8px 24px #010409;
  --shadow-xl: 0 12px 48px #010409;
  --shadow-focus: 0 0 0 3px #0c2d6b;
  --table-border-row: #30363d80;
  --counter-bg-1: #6e7681;
  --counter-text-1: #c9d1d9;
  --text-on-inverse: #24292e;
  --inverse: #e4e6eb;
  --text-default: var(--gray-3);
  --text-subtle: var(--gray-4);
  --text-muted: var(--gray-7);
  --text-placeholder: var(--gray-7);
  --text-disabled: #ffffff4d;
  --text-tooltip: #f0f6fc;
  --table-header-bg: var(--gray-8);
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  color: var(--text-default);
  background: var(--bg-default);
  font-family: CircularSp, Helvetica, Arial, sans-serif;
  font-size: var(--font-size-body-1);
  max-width: 100%;
  height: 100vh;
  min-height: 100vh;
  line-height: 1.375rem;
}

#root {
  height: 100%;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  body ::-webkit-scrollbar {
    background: none;
    width: .4375rem;
    height: .4375rem;
  }

  body ::-webkit-scrollbar-thumb {
    background-color: #0000001a;
    border-radius: 1rem;
  }

  body :hover::-webkit-scrollbar-thumb {
    background-color: #64646433;
  }
}

.recharts-tooltip-wrapper {
  outline: 0;
}

.recharts-wrapper svg {
  overflow: inherit !important;
}

html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  tab-size: 4;
  word-break: normal;
}

*, :after, :before {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

:after, :before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  margin: 0;
  padding: 0;
}

hr {
  color: inherit;
  height: 0;
  overflow: visible;
}

details, main {
  display: block;
}

summary {
  display: list-item;
}

small {
  font-size: 80%;
}

[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

code, kbd, pre, samp {
  font-family: monospace;
}

pre {
  font-size: 1em;
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-color: inherit;
  text-indent: 0;
}

iframe {
  border-style: none;
}

input {
  border-radius: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

optgroup {
  font-weight: 700;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"], [type="button"], [type="reset"], [type="submit"], button {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

[type="reset"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

button, input, select, textarea {
  background-color: #0000;
  border-style: none;
}

a:focus, button:focus, input:focus, select:focus, textarea:focus {
  outline-width: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  color: inherit;
  white-space: normal;
  border: 0;
  max-width: 100%;
  display: table;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}

[disabled] {
  cursor: default;
}

img {
  border-style: none;
}

progress {
  vertical-align: baseline;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"] {
  cursor: default;
}

:root {
  --encore-title-font-stack: CircularSpTitle, CircularSpTitle-Tall, CircularSp-Arab, CircularSp-Hebr, CircularSp-Cyrl, CircularSp-Grek, CircularSp-Deva, var(--fallback-fonts, sans-serif);
  --encore-body-font-stack: CircularSp, CircularSp-Arab, CircularSp-Hebr, CircularSp-Cyrl, CircularSp-Grek, CircularSp-Deva, var(--fallback-fonts, sans-serif);
}

.encore-text-headline-large {
  font-size: var(--encore-text-size-larger-5);
  font-family: var(--encore-title-font-stack);
  font-weight: 900;
}

.encore-text-headline-medium {
  font-size: var(--encore-text-size-larger-4);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-title-large {
  font-size: var(--encore-text-size-larger-3);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-title-medium {
  font-size: var(--encore-text-size-larger-2);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-title-small {
  font-size: var(--encore-text-size-larger);
  font-family: var(--encore-title-font-stack);
  font-weight: 700;
}

.encore-text-body-medium {
  font-size: var(--encore-text-size-base);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}

.encore-text-body-medium-bold {
  font-size: var(--encore-text-size-base);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}

.encore-text-body-small {
  font-size: var(--encore-text-size-smaller);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}

.encore-text-body-small-bold {
  font-size: var(--encore-text-size-smaller);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}

.encore-text-marginal {
  font-size: var(--encore-text-size-smaller-2);
  font-family: var(--encore-body-font-stack);
  font-weight: 400;
}

.encore-text-marginal-bold {
  font-size: var(--encore-text-size-smaller-2);
  font-family: var(--encore-body-font-stack);
  font-weight: 700;
}

@media (min-width: 768px) {
  .encore-layout-themes {
    --encore-text-size-smaller-2: .75rem;
    --encore-text-size-smaller: .875rem;
    --encore-text-size-base: 1rem;
    --encore-text-size-larger: 1.5rem;
    --encore-text-size-larger-2: 2rem;
    --encore-text-size-larger-3: 3rem;
    --encore-text-size-larger-4: 4rem;
    --encore-text-size-larger-5: 6rem;
    --encore-border-width-hairline: 1px;
    --encore-border-width-thin: 2px;
    --encore-border-width-thick: 4px;
    --encore-border-width-thicker: 8px;
    --encore-border-width-focus: 3px;
    --encore-graphic-size-decorative-smaller-2: 12px;
    --encore-graphic-size-decorative-smaller: 16px;
    --encore-graphic-size-decorative-base: 24px;
    --encore-graphic-size-decorative-larger: 32px;
    --encore-graphic-size-decorative-larger-2: 40px;
    --encore-graphic-size-decorative-larger-3: 48px;
    --encore-graphic-size-decorative-larger-4: 64px;
    --encore-graphic-size-decorative-larger-5: 88px;
    --encore-graphic-size-informative-smaller-2: .75rem;
    --encore-graphic-size-informative-smaller: 1rem;
    --encore-graphic-size-informative-base: 1.5rem;
    --encore-graphic-size-informative-larger: 2rem;
    --encore-graphic-size-informative-larger-2: 2.5rem;
    --encore-graphic-size-informative-larger-3: 3rem;
    --encore-graphic-size-informative-larger-4: 4rem;
    --encore-graphic-size-informative-larger-5: 5.5rem;
    --encore-spacing-tighter-5: 2px;
    --encore-spacing-tighter-4: 4px;
    --encore-spacing-tighter-3: 6px;
    --encore-spacing-tighter-2: 8px;
    --encore-spacing-tighter: 12px;
    --encore-spacing-base: 16px;
    --encore-spacing-looser: 24px;
    --encore-spacing-looser-2: 32px;
    --encore-spacing-looser-3: 48px;
    --encore-spacing-looser-4: 64px;
    --encore-spacing-looser-5: 96px;
    --encore-spacing-looser-6: 128px;
    --encore-control-size-smaller: 32px;
    --encore-control-size-base: 48px;
    --encore-control-size-larger: 56px;
    --encore-layout-margin-tighter: 24px;
    --encore-layout-margin-base: 32px;
    --encore-layout-margin-looser: 64px;
    --encore-corner-radius-smaller: 2px;
    --encore-corner-radius-base: 4px;
    --encore-corner-radius-larger: 6px;
    --encore-corner-radius-larger-2: 8px;
    --encore-corner-radius-larger-3: 16px;
  }
}

.encore-medium-devices-theme {
  --encore-text-size-smaller-2: .75rem;
  --encore-text-size-smaller: .875rem;
  --encore-text-size-base: 1rem;
  --encore-text-size-larger: 1.5rem;
  --encore-text-size-larger-2: 2rem;
  --encore-text-size-larger-3: 3rem;
  --encore-text-size-larger-4: 4rem;
  --encore-text-size-larger-5: 6rem;
  --encore-border-width-hairline: 1px;
  --encore-border-width-thin: 2px;
  --encore-border-width-thick: 4px;
  --encore-border-width-thicker: 8px;
  --encore-border-width-focus: 3px;
  --encore-graphic-size-decorative-smaller-2: 12px;
  --encore-graphic-size-decorative-smaller: 16px;
  --encore-graphic-size-decorative-base: 24px;
  --encore-graphic-size-decorative-larger: 32px;
  --encore-graphic-size-decorative-larger-2: 40px;
  --encore-graphic-size-decorative-larger-3: 48px;
  --encore-graphic-size-decorative-larger-4: 64px;
  --encore-graphic-size-decorative-larger-5: 88px;
  --encore-graphic-size-informative-smaller-2: .75rem;
  --encore-graphic-size-informative-smaller: 1rem;
  --encore-graphic-size-informative-base: 1.5rem;
  --encore-graphic-size-informative-larger: 2rem;
  --encore-graphic-size-informative-larger-2: 2.5rem;
  --encore-graphic-size-informative-larger-3: 3rem;
  --encore-graphic-size-informative-larger-4: 4rem;
  --encore-graphic-size-informative-larger-5: 5.5rem;
  --encore-spacing-tighter-5: 2px;
  --encore-spacing-tighter-4: 4px;
  --encore-spacing-tighter-3: 6px;
  --encore-spacing-tighter-2: 8px;
  --encore-spacing-tighter: 12px;
  --encore-spacing-base: 16px;
  --encore-spacing-looser: 24px;
  --encore-spacing-looser-2: 32px;
  --encore-spacing-looser-3: 48px;
  --encore-spacing-looser-4: 64px;
  --encore-spacing-looser-5: 96px;
  --encore-spacing-looser-6: 128px;
  --encore-control-size-smaller: 32px;
  --encore-control-size-base: 48px;
  --encore-control-size-larger: 56px;
  --encore-layout-margin-tighter: 24px;
  --encore-layout-margin-base: 32px;
  --encore-layout-margin-looser: 64px;
  --encore-corner-radius-smaller: 2px;
  --encore-corner-radius-base: 4px;
  --encore-corner-radius-larger: 6px;
  --encore-corner-radius-larger-2: 8px;
  --encore-corner-radius-larger-3: 16px;
}

/*# sourceMappingURL=index.1820ee61.css.map */
